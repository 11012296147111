import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { NgModule } from '@angular/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { appRoutingProviders, routing } from '../app/app.routes';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { IntroComponent } from './intro';
import { IntroTextComponent } from './intro/intro-text/intro-text.component';
import { ConversationService } from './messenger/conversation.service';
import { TestService } from './shared-tests/test.service';
import { CustomHammerConfig } from './shared/hammer/custom-hammer-config';
import { RequestHeaderService } from './shared/request-header/request-header.service';
import { SharedModule } from './shared/shared.module';
import { NoAccountComponent } from './user/no-account/no-account.component';
import { VerificationReminderComponent } from './signup/verification-reminder/verification-reminder.component';
import { MatCardModule } from '@angular/material/card';

registerLocaleData(localeDE);
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MM.YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MM.YYYY',
  },
};

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  connectOnCreate: false,
  hostname: environment.mqtt.host,
  port: environment.mqtt.port,
  protocol: environment.mqtt.secure ? 'wss' : 'ws',
};

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieConsentUrl
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#E91E63'
    }
  },
  theme: 'classic',
  position: 'bottom-left',
  type: 'opt-in',
  content: {
    message: 'Diese Website verwendet Cookies, um Ihnen die bestmögliche Nutzung unserer Website zu ermöglichen.',
    dismiss: 'Alles klar!',
    deny: 'Notwendige erlauben',
    allow: 'Alle erlauben',
    link: 'Erfahre mehr',
    href: 'https://berufsweg.de/admin/datenschutz'
  }
};


@NgModule({
  declarations: [
    AppComponent,
    IntroComponent,
    NoAccountComponent,
    IntroTextComponent,
    VerificationReminderComponent,
  ],
  bootstrap: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    routing,
    SharedModule,
    CoreModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMomentDateModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    NgcCookieConsentModule.forRoot(cookieConfig),
    MatCardModule
  ],
  exports: [
    VerificationReminderComponent
  ],
  providers: [
    appRoutingProviders,
    RequestHeaderService,
    TestService,
    ConversationService,
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    // { provide: MAT_ICON_DEFAULT_OPTIONS, useValue: { fontSet: 'material-icons-outlined' } },
    { provide: HAMMER_GESTURE_CONFIG, useClass: CustomHammerConfig },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    provideHttpClient(withInterceptorsFromDi()),
  ]
})
export class AppModule {
}
